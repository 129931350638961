@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.login-img {
  background-image: url("./Images//Frame.svg");
}

.login2-img {
  background: url("./Images/biyaLogo.jpg"),
    lightgray -101.056px -150.456px / 157.895% 378.947% no-repeat;
}

.no-form-bx-sh {
  box-shadow: none;
}
